import React, { useEffect } from 'react';
import { Button, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { SimpleDialog } from '../modals/SimpleDialog';
import * as ROUTES from 'src/shared/constants/routes';
import RetakeAssessmentModal from 'src/modules/Assessment/components/RetakeAssessmentModal';
import {
  retakeAssessment,
  selectAssessmentState
} from 'src/modules/Assessment/services/assessment.service';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  selectUserState,
  setTenantId,
  setUnitId,
  setUserId
} from 'src/auth/login/services/userSlice';
import snapAssessLogo from 'src/assets/images/snap.assess_logo.svg';
import { useTranslation } from 'react-i18next';
import { I18N } from 'src/shared/constants/constants';
import { useNavigate } from 'react-router';

const Header = () => {
  const { i18n, t } = useTranslation(I18N.DEFAULT);
  const navigate = useNavigate();
  const [retakeClick, setRetakeClick] = React.useState<boolean>(false);
  const [openRetakeAssessmentModal, setOpenRetakeAssessmentModal] =
    React.useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const { tenantId, unitId, userId } = useSelector(selectUserState);
  const { unit, assessmentData } = useSelector(selectAssessmentState);

  const showRetakeAssessmentButton =
    assessmentData?.every((job) => job?.status === 'COMPLETED') ?? false;

  const [retakeClickDisplay, setRetakeClickDisplay] =
    React.useState<boolean>(false);

  const showAllAssessmentButton =
    window.location.pathname === '/show-all-assessment';

  useEffect(() => {
    if (window.location.pathname === '/admin/assessment') {
      setRetakeClickDisplay(true);
    } else {
      setRetakeClickDisplay(false);
    }
  }, [window.location.pathname]);

  const handleOpenRetakeAssessmentModal = () => {
    setRetakeClick(!retakeClick);
    setOpenRetakeAssessmentModal(!openRetakeAssessmentModal);
  };

  const handleOnRetakeAssessmentConfirm = () => {
    dispatch(retakeAssessment({ user_id: userId, unit_id: unitId }));
    setRetakeClick(true);
    handleOpenRetakeAssessmentModal();
  };

  const handleShowAllAssessmentClick = () => {
    dispatch(setUserId(null));
    dispatch(setTenantId(null));
    dispatch(setUnitId(null));
    navigate(`${ROUTES.SHOW_ALL_ASSESSMENTS}`);
  };

  return (
    <Box className="header d-flex flex-basic-space-around">
      <Box className="header-logo">
        <img alt="IllustrationLogin" src={snapAssessLogo}></img>
      </Box>
      <Box className="header-title">
        {/* <span>Python for Data Science and Machine Learning Bootcamp</span> */}
        {!showAllAssessmentButton && (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ p: 2 }}
          >
            <div className="header-title">{unit?.name}</div>
          </Stack>
        )}
      </Box>
      <Box sx={{ marginRight: '40px' }}>
        {!showAllAssessmentButton && (
          <Button variant="contained" onClick={handleShowAllAssessmentClick}>
            {t('my-assessments')}
          </Button>
        )}
        {showRetakeAssessmentButton && retakeClickDisplay && (
          <Button
            sx={{ marginLeft: '10px' }}
            variant="contained"
            onClick={handleOpenRetakeAssessmentModal}
          >
            {t('retake-assessment')}
          </Button>
        )}
      </Box>
      <SimpleDialog
        keepMounted
        open={openRetakeAssessmentModal}
        onClose={handleOpenRetakeAssessmentModal}
        maxWidth={'sm'}
        model_content={
          <RetakeAssessmentModal
            handleClose={handleOpenRetakeAssessmentModal}
            handleOnRetakeAssessmentConfirm={handleOnRetakeAssessmentConfirm}
          />
        }
      />
    </Box>
  );
};

export default Header;
